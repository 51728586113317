<template>
    <div class="riskMatrix">


        <table>
            <tr>
                <td rowspan="7" class="" style="text-align:center;border:0">IMPACT</td>
            </tr>
            <tr>
            <td>Critical</td>
            <td class="orange" :style="calcColor(5)" data-coords="5_1" @click="setValue(1,5,5)" width="10%"><div :class="[isSelected(1,5) ? 'circle' : '']">5</div></td>
            <td class="orange" :style="calcColor(10)" data-coords="5_2" @click="setValue(2,5,10)" width="10%"><div :class="[isSelected(2,5) ? 'circle' : '']">10</div></td>
            <td class="red" :style="calcColor(15)" data-coords="5_3" @click="setValue(3,5,15)" width="10%"><div :class="[isSelected(3,5) ? 'circle' : '']">15</div></td>
            <td class="red" :style="calcColor(20)" data-coords="5_4" @click="setValue(4,5,20)" width="10%"><div :class="[isSelected(4,5) ? 'circle' : '']">20</div></td>
            <td class="red" :style="calcColor(25)" data-coords="5_5" @click="setValue(5,5,25)" width="10%"><div :class="[isSelected(5,5) ? 'circle' : '']">25</div></td>
            </tr>
            <tr>
            <td>High</td>
            <td class="yellow" :style="calcColor(4)"  data-coords="4_1" @click="setValue(1,4,4)"><div :class="[isSelected(1,4) ? 'circle' : '']">4</div></td>
            <td class="orange" :style="calcColor(8)"  data-coords="4_2" @click="setValue(2,4,8)"><div :class="[isSelected(2,4) ? 'circle' : '']">8</div></td>
            <td class="orange" :style="calcColor(12)"  data-coords="4_3" @click="setValue(3,4,12)"><div :class="[isSelected(3,4) ? 'circle' : '']">12</div></td>
            <td class="red" :style="calcColor(16)"  data-coords="4_4" @click="setValue(4,4,16)"><div :class="[isSelected(4,4) ? 'circle' : '']">16</div></td>
            <td class="red" :style="calcColor(20)"  data-coords="4_5" @click="setValue(5,4,20)"><div :class="[isSelected(5,4) ? 'circle' : '']">20</div></td>
            </tr>
            <tr>
            <td>Medium</td>
            <td class="yellow" :style="calcColor(3)"  data-coords="3_1" @click="setValue(1,3,3)"><div :class="[isSelected(1,3) ? 'circle' : '']">3</div></td>
            <td class="yellow" :style="calcColor(6)"  data-coords="3_2" @click="setValue(2,3,6)"><div :class="[isSelected(2,3) ? 'circle' : '']">6</div></td>
            <td class="yellow" :style="calcColor(9)"  data-coords="3_3" @click="setValue(3,3,9)"><div :class="[isSelected(3,3) ? 'circle' : '']">9</div></td>
            <td class="orange" :style="calcColor(12)"  data-coords="3_4" @click="setValue(4,3,12)"><div :class="[isSelected(4,3) ? 'circle' : '']">12</div></td>
            <td class="red" :style="calcColor(15)"  data-coords="3_5"    @click="setValue(5,3,15)"><div :class="[isSelected(5,3) ? 'circle' : '']">15</div></td>
            </tr>
            <tr>
            <td>Low</td>
            <td class="green" :style="calcColor(2)"  data-coords="2_1" @click="setValue(1,2,2)"><div :class="[isSelected(1,2) ? 'circle' : '']">2</div></td>
            <td class="green" :style="calcColor(4)"  data-coords="2_2" @click="setValue(2,2,4)"><div :class="[isSelected(2,2) ? 'circle' : '']">4</div></td>
            <td class="yellow" :style="calcColor(6)"  data-coords="2_3" @click="setValue(3,2,6)"><div :class="[isSelected(3,2) ? 'circle' : '']">6</div></td>
            <td class="yellow" :style="calcColor(8)"  data-coords="2_4" @click="setValue(4,2,8)"><div :class="[isSelected(4,2) ? 'circle' : '']">8</div></td>
            <td class="orange" :style="calcColor(10)"  data-coords="2_5" @click="setValue(5,2,10)"><div :class="[isSelected(5,2) ? 'circle' : '']">10</div></td>
            </tr>
            <tr>
            <td>Negligible</td>
            <td class="green" :style="calcColor(1)"  data-coords="1_1" @click="setValue(1,1,1)"><div :class="[isSelected(1,1) ? 'circle' : '']">1</div></td>
            <td class="green" :style="calcColor(2)"  data-coords="1_2" @click="setValue(2,1,2)"><div :class="[isSelected(2,1) ? 'circle' : '']">2</div></td>
            <td class="green" :style="calcColor(3)"  data-coords="1_3" @click="setValue(3,1,3)"><div :class="[isSelected(3,1) ? 'circle' : '']">3</div></td>
            <td class="yellow" :style="calcColor(4)"  data-coords="1_4" @click="setValue(4,1,4)"><div :class="[isSelected(4,1) ? 'circle' : '']">4</div></td>
            <td class="yellow" :style="calcColor(5)"  data-coords="1_5" @click="setValue(5,1,5)"><div :class="[isSelected(5,1) ? 'circle' : '']">5</div></td>
            </tr>
            <tr>
            <td>&nbsp;</td>
            <td>Rare</td>
            <td>Unlikely</td>
            <td>Possible</td>
            <td>Likely</td>
            <td>Certainty</td>
            </tr>
            <tr>
                <td colspan="7" style="text-align:center;border:0" >PROBABILITY</td>
            </tr>
        </table>
        <b>NOTES<br/>Risk Review Period : {{calcPeriod(risk)}}</b><br/>

        <span><small>

<b>Probability</b><br/>
<p>Probability (x axis) pertains to the extent of how likely it is for the risk to occur. The 5 risk rating levels under this component are as follows:</p>
<ul>
<li>Rare – unlikely to happen.</li>
<li>Unlikely – possible to happen.</li>
<li>Possible – likely to happen.</li>
<li>Likely – almost sure to happen.</li>
<li>Certainty – sure to happen</li>

</ul>
<b>Impact</b><br/>
<p>Impact (y axis) aims to determine the level of effects that the risk can cause.</p>

<ul>
<li>Negligible – won’t cause serious impact to the business</li>
<li>Low – can cause minor impact to the business</li>
<li>Medium – can cause significant impact to the business</li>
<li>High – can cause major impacts to the business</li>
<li>Critial – will cause Severe impact to the business.</li>
</ul>


        </small></span>

    </div>
</template>
<script>

export default {
  name: "RiskMatrix",
  props: {
    likelyhood: Number,
    impact: Number,
    risk: Number,
    riskreview: Object
  },
  mounted() {
  },  
  methods: {
      setValue(likelyhood,impact,risk) {
          console.log(likelyhood,impact,risk);
          this.$emit("riskSelected",{
              likelyhood,impact,risk
          });

      },
      isSelected(likelyhood,impact) {
          if (this.likelyhood) {
              if ( this.likelyhood == likelyhood && this.impact == impact ) {
                  return true;
              }
          }
          return false;
      },
      calcColor(score) {
        if (score <= this.riskreview.low_score) {
          return 'background-color: ' + this.riskreview.low_color + ' !important';
        } else if (score <= this.riskreview.med_score) {
          return 'background-color: ' + this.riskreview.med_color + ' !important';
        } else if (score <= this.riskreview.high_score) {
          return 'background-color: ' + this.riskreview.high_color + ' !important';
        } else {
          return 'background-color: ' + this.riskreview.vhigh_color + ' !important';
        }
      },

      calcPeriod(score) {
        if (score <= this.riskreview.low_score) {
          return this.riskreview.low_review + ' months';
        } else if (score <= this.riskreview.med_score) {
          return this.riskreview.med_review + ' months';
        } else if (score <= this.riskreview.high_score) {
          return this.riskreview.high_review + ' months';
        } else {
          return this.riskreview.vhigh_review + ' month';
        }
      }      
  },
  computed: {
  },  
}
</script>

<style lang="scss">

.rotate {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.circle {
    width:40px;
    height:40px;
    border-radius:30px;
    line-height:40px;
    text-align:center;
    background:#000;
    color:white;
  margin: 0 auto;
}

div.riskMatrix {
  width: 100%;
  float: left;
  
  table {
    height: 100%;
    width: 100%;
    
    tr {
      &:last-of-type {
        td {
          text-align: center;
          padding: 10px;
          
          font-size: 1.2em;
          font-weight: bold;
        }
      }
      
      td {
        height: 5vh;
        width: 20vw;
        text-align: center;
          font-size: 1.2em;
          font-weight: bold;



        
        border: 1px solid rgba(0,0,0, .2);
        
        &:first-of-type { 
          width: 30px;
          height: 30px;
          
          margin-top: 10px;
          
          font-size: 1.2em;
          font-weight: bold;
          
          text-align: right;
          padding: 20px;
        }
        
        &.green {
            background-color: #44C14A;
            cursor:pointer;
        }
        
        &.orange {
            background-color: #FFA500;
            cursor:pointer;
        }
        
        &.yellow {
            background-color: #FFFF00;
            cursor:pointer;          
        }
        
        &.red {
            background-color: #BB413E;
            cursor:pointer;          
        }
      }
    }
  }
}
</style>